import React from 'react'
import useRemark from 'hooks/useRemark'
import Component from './index.js'
import { extractGroup } from '../StoryblokGrid'

export default function EditableTransformer({ data, ...props }) {
  const contactInfo = useRemark(data.contactInfo)
  const button = extractGroup(data.button || data, ['targetUrl', 'label'])

  return <Component data={{ ...data, contactInfo, button }} {...props} />
}
